import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'
import Button from '../components/button'
import Hero from '../components/hero'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import LocalImageHook from '../hooks/local-images-hook'
import ContentfulImageHook from '../hooks/contentful-images-hook'

export default ({data}) => {
  const title = 'BODY OF WORK'
  const heroImage = LocalImageHook().bodyofworks_cover.childImageSharp
  const bow = data.bow.nodes
  console.log(bow)
  const options = bow.map((work,idx)=>{
    return {
      title:work.title,
      image:work.cover,
      slug:work.slug,
      title_en:work.subtitle,
      bookingLink:work.bookingLink
    }
  })

  return (
    <Layout>
      <Helmet title={title} />
      <div className="px-6 mx-auto py-8 md:px-20 md:pt-12 md:pb-12">
        <h1 className="uppercase font-light tracking-widest text-gray-33 text-20 md:text-36">
          BODY OF WORK
        </h1>
      </div>
      <Hero
        title="RYZEN HOUSE  會呼吸的建築"
        description={`美術館的延伸 夢想的初始<br>
        不只世界，不只美術，而且生活。`}
        heroImage={heroImage}
      />
      <div className="px-6 mt-10 mx-auto mb-20 md:px-20 md:mt-40">
        <div className="grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-10">
          {options.map(({ title, title_en, image,slug,bookingLink }, index) => (
            <div className="w-full h-auto md:relative group" key={`workopt_${index}`}>
              <Link to={ bookingLink ? '/booking/' : `/body-of-work/${slug.toLowerCase()}`}>
                <div className="w-full pb-full relative md:pb-0">
                  <div className="absolute top-0 left-0 w-full h-full md:static">
                    <Img className="w-full h-full" fluid={image.fluid} />
                  </div>
                </div>
                <div className="overlay bg-gray-f7 inset-0 absolute opacity-0 transition-all duration-300 ease-in md:group-hover:opacity-75"></div>
                <div className="mt-2 md:mt-0 md:absolute md:top-0 md:left-0 md:ml-8 md:mt-10 md:opacity-0 md:group-hover:opacity-100 transition-all duration-300 ease-out delay-200">
                  <h4 className="text-14-25 text-gray-4d font-medium md:text-lg md:leading-relaxed">
                    {title_en}
                    <br />
                    {title}
                  </h4>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>

    </Layout>
  )
}

export const bodyOfWorkQuery = graphql`
  {
    bow:allContentfulBodyOfWork(sort: {order: ASC, fields: order}) {
      nodes {
        slug
        title
        subtitle
        bookingLink
        cover {
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
