import React from 'react'
import Img from 'gatsby-image'
// import Img from "gatsby-image/withIEPolyfill"

export default ({ title = '', description = '', heroImage = null }) => {
  return (
    <div className="relative h-412 w-full md:h-42vw md:w-screen">
      {heroImage ? (
        <>
          <Img
            className="h-412 w-full md:h-42vw md:w-screen"
            fluid={heroImage.fluid}
          />
        </>
      ) : null}
      <div className="absolute inset-0 px-6 mx-auto md:px-20 text-white flex flex-col justify-end pb-24">
        <div>
          <h3 className="font-bold text-17-25 md:text-30">{title}</h3>
          <p
            className="font-regular text-13-18 mt-4 md:text-lg md:leading-relaxed"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </div>
  )
}
