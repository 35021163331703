import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const LocalImageHook = () => {
  const data = useStaticQuery(graphql`
    {
      about_cover: file(relativePath: { eq: "about_cover.jpg" }) {
        id
        childImageSharp {
          og: fixed(quality: 10, width: 1200, height: 630) {
            src
          }
          fixed(quality: 10) {
            ...GatsbyImageSharpFixed_withWebp
          }
          fluid(quality: 10) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bodyofworks_cover: file(relativePath: { eq: "bodyofworks_cover.jpg" }) {
        id
        childImageSharp {
          og: fixed(quality: 10, width: 1200, height: 630) {
            src
          }
          fixed(quality: 10) {
            ...GatsbyImageSharpFixed_withWebp
          }
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return data
}

export default LocalImageHook
