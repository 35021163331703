import React from 'react'

export default ({
  children,
  className = '',
  Padding = 'py-3',
  onClick = () => {},
  Tag = 'div',
  textSize = 'lg',
  ...props
}) => {
  return (
    <Tag
      onClick={onClick}
      className={`border border-gray-4d ${Padding} inline-flex items-center justify-center text-13-20 text-${textSize} text-gray-4d ${className} hover:bg-gray-4d hover:text-white focus:outline-none`}
      {...props}
    >
      {children}
    </Tag>
  )
}
